@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Monoton:wght@200;300;400;500;600;700;800&display=swap');


*{
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    list-style: none;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.sidebar{
    color: #fff;
}

.logo{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

}

.logo-icon{
    width: 58px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    border-radius: 10%;
    color: #fff;
}
.logo-img{
    width: 70%;
    height: 70%;
}


.menu-bar{
    height: 94vh ;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 1rem;
    position: relative;

}


.layout-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: #fff;
    z-index: 1000;
    height: 5vh;
    position: relative;

    
}

@media screen and (max-height: 650px) {
    .layout-header {
        margin-top: 10px; 
    }
}

.layout-body{
    background: #fff;
}

.layout-page{
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.SelectedBtn{
    display: flex;
    align-items: center;
    justify-content: flex-end;

}
