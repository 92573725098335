
.Row {
    display: flex;
    gap: 10px;
    margin-top: 1%;
  }

  .form-label {
    display: flex;
    justify-items: center;
    justify-content: center;
}