* {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
   }


   .InputIcon{
    padding-left: 30%;
   }
    
   body,
   .LoginInput {
     font-family: "Poppins", sans-serif;
   }
    
   .loginContainer {
     position: relative;
     width: 100%;
     background-color: #fff;
     min-height: 100vh;
     overflow: hidden;
   }
    
   .forms-container {
     position: absolute;
     width: 100%;
     height: 100%;
     top: 0;
     left: 0;
   }
    
   .signin-signup {
     position: absolute;
     top: 50%;
     transform: translate(-50%, -50%);
     left: 75%;
     width: 50%;
     transition: 1s 0.7s ease-in-out;
     display: grid;
     grid-template-columns: 1fr;
     z-index: 5;
   }
    
   .loginForm {
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     padding: 0rem 5rem;
     transition: all 0.2s 0.7s;
     overflow: hidden;
     grid-column: 1 / 2;
     grid-row: 1 / 2;
   }
    
   .loginForm.sign-up-form {
     opacity: 0;
     z-index: 1;
   }
    
   .loginForm.sign-in-form {
     z-index: 2;
   }
    
   .title {
     font-size: 2.2rem;
     color: #444;
     margin-bottom: 10px;
   }
    
   .input-field {
    display: flex;
    justify-content: center;
    align-items: center;
     max-width: 380px;
     width: 100%;
     background-color: #f0f0f0;
     margin: 10px 0;
     height: 55px;
     border-radius: 55px;
     display: grid;
     grid-template-columns: 15% 85%;
     padding: 0 0.4rem;
     position: relative;
   }
    
   .input-field .i {
     text-align: center;
     line-height: 55px;
     color: #acacac;
     transition: 0.5s;
     font-size: 1.1rem;
   }
    
   .input-field .LoginInput {
     background: none;
     outline: none;
     border: none;
     line-height: 1;
     font-weight: 600;
     font-size: 1.1rem;
     color: #333;
   }
    
   .input-field .LoginInput::placeholder {
     color: #aaa;
     font-weight: 500;
   }
    
  
   .LinkBtn{
    color: #fff;
    text-decoration: none;
   }
    
   .btn {
     width: 150px;
     background-color: #5995fd;
     border: none;
     outline: none;
     height: 49px;
     border-radius: 49px;
     color: #fff;
     text-transform: uppercase;
     font-weight: 600;
     margin: 10px 0;
     cursor: pointer;
     transition: 0.5s;
   }
    
   .btn:hover {
     background-color: #4d84e2;
   }
   .panels-container {
     position: absolute;
     height: 100%;
     width: 100%;
     top: 0;
     left: 0;
     display: grid;
     grid-template-columns: repeat(2, 1fr);
   }
    
   .loginContainer:before {
     content: "";
     position: absolute;
     height: 2000px;
     width: 2000px;
     top: -10%;
     right: 48%;
     transform: translateY(-50%);
     background-image: linear-gradient(-45deg, #FF8C00 0%, #FFA500 100%);
     transition: 1.8s ease-in-out;
     border-radius: 50%;
     z-index: 6;
   }
    
   .image {
     width: 100%;
     transition: transform 1.1s ease-in-out;
     transition-delay: 0.4s;
   }
    
   .panel {
     display: flex;
     flex-direction: column;
     align-items: flex-end;
     justify-content: space-around;
     text-align: center;
     z-index: 6;
   }
    
   .left-panel {
     pointer-events: all;
     padding: 3rem 17% 2rem 12%;
   }
    
   .right-panel {
     pointer-events: none;
     padding: 3rem 12% 2rem 17%;
   }
    
   .panel .content {
     color: #fff;
     transition: transform 0.9s ease-in-out;
     transition-delay: 0.6s;
   }
    
   .panel .loginh3 {
     font-weight: 600;
     line-height: 1;
     font-size: 1.5rem;
   }
    
   .panel .loginp {
     font-size: 0.95rem;
     padding: 0.7rem 0;
   }
    
   .btn.transparent {
     margin: 0;
     background: none;
     border: 2px solid #fff;
     width: 130px;
     height: 41px;
     font-weight: 600;
     font-size: 0.8rem;
   }
    
   .right-panel .image,
   .right-panel .content {
     transform: translateX(800px);
   }
    
   /* ANIMATION */
    
   .loginContainer.sign-up-mode:before {
     transform: translate(100%, -50%);
     right: 52%;
   }
    
   .loginContainer.sign-up-mode .left-panel .image,
   .loginContainer.sign-up-mode .left-panel .content {
     transform: translateX(-800px);
   }
    
   .loginContainer.sign-up-mode .signin-signup {
     left: 25%;
   }
    
   .loginContainer.sign-up-mode .loginForm.sign-up-form {
     opacity: 1;
     z-index: 2;
   }
    
   .loginContainer.sign-up-mode .loginForm.sign-in-form {
     opacity: 0;
     z-index: 1;
   }
    
   .loginContainer.sign-up-mode .right-panel .image,
   .loginContainer.sign-up-mode .right-panel .content {
     transform: translateX(0%);
   }
    
   .loginContainer.sign-up-mode .left-panel {
     pointer-events: none;
   }
    
   .loginContainer.sign-up-mode .right-panel {
     pointer-events: all;
   }
    
   @media (max-width: 870px) {
     .loginContainer {
       min-height: 800px;
       height: 100vh;
     }
     .signin-signup {
       width: 100%;
       top: 95%;
       transform: translate(-50%, -100%);
       transition: 1s 0.8s ease-in-out;
     }
    
     .signin-signup,
     .loginContainer.sign-up-mode .signin-signup {
       left: 50%;
     }
    
     .panels-container {
       grid-template-columns: 1fr;
       grid-template-rows: 1fr 2fr 1fr;
     }
    
     .panel {
       flex-direction: row;
       justify-content: space-around;
       align-items: center;
       padding: 2.5rem 8%;
       grid-column: 1 / 2;
     }
    
     .right-panel {
       grid-row: 3 / 4;
     }
    
     .left-panel {
       grid-row: 1 / 2;
     }
    
     .image {
       width: 200px;
       transition: transform 0.9s ease-in-out;
       transition-delay: 0.6s;
     }
    
     .panel .content {
       padding-right: 15%;
       transition: transform 0.9s ease-in-out;
       transition-delay: 0.8s;
     }
    
     .panel .loginh3 {
       font-size: 1.2rem;
     }
    
     .panel .loginp {
       font-size: 0.7rem;
       padding: 0.5rem 0;
     }
    
     .btn.transparent {
       width: 110px;
       height: 35px;
       font-size: 0.7rem;
     }
    
     .loginContainer:before {
       width: 1500px;
       height: 1500px;
       transform: translateX(-50%);
       left: 30%;
       bottom: 68%;
       right: initial;
       top: initial;
       transition: 2s ease-in-out;
     }
    
     .loginContainer.sign-up-mode:before {
       transform: translate(-50%, 100%);
       bottom: 32%;
       right: initial;
     }
    
     .loginContainer.sign-up-mode .left-panel .image,
     .loginContainer.sign-up-mode .left-panel .content {
       transform: translateY(-300px);
     }
    
     .loginContainer.sign-up-mode .right-panel .image,
     .loginContainer.sign-up-mode .right-panel .content {
       transform: translateY(0px);
     }
    
     .right-panel .image,
     .right-panel .content {
       transform: translateY(300px);
     }
    
     .loginContainer.sign-up-mode .signin-signup {
       top: 5%;
       transform: translate(-50%, 0);
     }
   }
    
   @media (max-width: 570px) {
     .loginForm {
       padding: 0 1.5rem;
     }
    
     .image {
       display: none;
     }
     .panel .content {
       padding: 0.5rem 1rem;
     }
     .loginContainer {
       padding: 1.5rem;
     }
    
     .loginContainer:before {
       bottom: 72%;
       left: 50%;
     }
    
     .loginContainer.sign-up-mode:before {
       bottom: 28%;
       left: 50%;
     }

   }

   .password-toggle-btn {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .recover-link {
    display: block;
    text-align: right;
    width: 44%;
    margin-top: -10px;
    background: none;  /* Fondo transparente */
    border: none;      /* Sin borde */
    color: inherit;    /* Heredar el color del texto */
    text-decoration: none;
    cursor: pointer;
    float: right;      /* Ajusta el enlace a la derecha */
  }
  
  .recover-link:hover {
    text-decoration: underline;
    text-decoration-color: #2e3644;
  }
  

